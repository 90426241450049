<template>
  <div ref="scrollDiv">
    <router-view></router-view>
    <div class="p32 p-t-32" v-if="$route.path == '/user/myOrder'">
      <div class="tt df">
        <div class="suxian"></div>
        我的订单
      </div>

      <div class="serchtab">
        <div class="li">
          <div class="serchName">商品名称</div>
          <div class="ipt">
            <el-input clearable placeholder="请输入名称" v-model="goodsName">
            </el-input>
          </div>
        </div>
        <div style="justify-content: center" class="li">
          <div class="serchName">客户</div>
          <div style="flex: 0" class="ipt">
            <el-input clearable placeholder="输入客户" v-model="customerName">
            </el-input>
          </div>
        </div>
        <div class="li">
          <div style="flex: 1" class="serchName">联系人</div>
          <div class="ipt">
            <el-input
              clearable
              placeholder="输入联系人名称"
              v-model="contactsName"
            >
            </el-input>
          </div>
        </div>
        <div class="li">
          <div class="serchName">订单编号</div>
          <div class="ipt">
            <el-input clearable placeholder="输入订单编号" v-model="orderSn">
            </el-input>
          </div>
        </div>
        <div style="justify-content: center" class="li">
          <div class="serchName">下单时间</div>
          <div style="flex: 0" class="ipt">
            <el-date-picker
              v-model="startDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择时间"
            ></el-date-picker>
          </div>
        </div>
        <div class="li">
          <div class="ss">
            <el-button @click="serchList" type="success">搜索</el-button>
            <el-button @click="resetFn">重置</el-button>
          </div>
        </div>
      </div>
      <div class="totalList">
        <div style="margin-bottom: 24px" class="df aic">
          <div
            @click="changeTabFn(1)"
            :class="changeTab == 1 ? 'hd active' : 'hd'"
          >
            全部订单
          </div>
          <div
            @click="changeTabFn(6)"
            :class="changeTab == 6 ? 'hd active' : 'hd'"
          >
            审批中
          </div>
          <div
            @click="changeTabFn(2)"
            :class="changeTab == 2 ? 'hd active' : 'hd'"
          >
            <el-badge
              v-if="orderCount.waitPayCount > 0"
              :value="orderCount.waitPayCount"
              :max="99"
              color="#ff2832"
              class="item"
              >待付款</el-badge
            >
            <div v-else>待付款</div>
          </div>
          <div
            @click="changeTabFn(3)"
            :class="changeTab == 3 ? 'hd active' : 'hd'"
          >
            <el-badge
              v-if="orderCount.payingCount > 0"
              :value="orderCount.payingCount"
              :max="99"
              color="#ff2832"
              class="item"
              >支付中</el-badge
            >
            <div v-else>支付中</div>
          </div>
          <div
            @click="changeTabFn(4)"
            :class="changeTab == 4 ? 'hd active' : 'hd'"
          >
            <el-badge
              v-if="orderCount.waitReceiveCount > 0"
              :value="orderCount.waitReceiveCount"
              :max="99"
              class="item"
              >待收货</el-badge
            >
            <div v-else>待收货</div>
          </div>
          <div
            @click="changeTabFn(5)"
            :class="changeTab == 5 ? 'hd active' : 'hd'"
          >
            <el-badge
              v-if="orderCount.completedCount > 0"
              :value="orderCount.completedCount"
              :max="99"
              class="item"
              >已完成</el-badge
            >
            <div v-else>已完成</div>
          </div>
          <!-- <div
            @click="changeTabFn(6)"
            :class="changeTab == 6 ? 'hd active' : 'hd'"
          >
            <div >支付中</div>
          </div> -->

          <!-- 更多状态 -->
          <!-- <div
            @mouseover="moreStatus = true"
            @mouseleave="moreStatus = false"
            class="moreStatus"
            :class="changeTab == 6 ? 'hd active' : 'hd'"
          >
            {{ otherStatus }}
            <div v-show="moreStatus" class="moreChild">
              <div
                v-for="(item, index) in otherStatusName"
                :key="index"
                @click="changeTabFn(6, item)"
                :class="otherStatus == item.name ? 'gr' : 'black'"
                class="item"
              >
                {{ item.name }}
              </div>
            </div>
          </div> -->
        </div>

        <div
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.8)"
        >
          <div v-if="productList.length == 0" class="none">
            <img src="@/assets/images/kongList.png" alt />
            <div class="empty-tips">
              您还没有订单哦!
              <span @click="$router.push('/home')" class="cup gr">去下单</span>
            </div>
          </div>
          <div v-if="productList.length > 0" class="order-list">
            <div class="li" v-for="(item, index) in productList" :key="index">
              <div class="head df">
                <div
                  v-if="changeTab == 2 || changeTab == 4"
                  style="margin-right: 0px; padding-top: 6px"
                  class="checkPay"
                >
                  <el-checkbox-group v-model="checkPay">
                    <el-checkbox
                      @change="choseinfo(item)"
                      :label="item.order.id"
                      >&nbsp;</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
                <div style="width: 100%" class="content">
                  <div class="bg"></div>
                  <li>
                    <div class="cld realpay">
                      {{ item.order.status > 110 ? "实" : "应" }}付总金额：
                      <span style="color:#E33233"
                        >¥
                        {{
                          item.order.status > 110
                            ? item.order.payAmount
                            : item.order.totalAmount
                        }}</span
                      >
                    </div>
                    <div class="su"></div>
                    <div class="cld">
                      订单号：
                      <span>{{ item.order.orderSn }}</span>
                    </div>
                    <div class="su"></div>

                    <div class="cld">
                      下单时间：
                      <span>{{ item.order.createTime }}</span>
                    </div>
                    <!-- <div class="su"></div>
                    <div class="cld cup" style="color: #26c487">
                      <div>
                        <i
                          style="
                            color: #26c487;
                            font-size: 13px;
                            margin-right: 6px;
                          "
                          class="el-icon-upload2"
                        ></i>
                      </div>
                      上传提货委托书
                    </div> -->
                    <div
                      class="cld"
                      style="color: #26c487; flex: 1; justify-content: flex-end"
                    >
                      <!-- 待付款状态 //订单状态：-1全部 审批中-50 待付款-100 支付中-110 待发货-120 部分发货-130 待收货-140 部分收货-150   已完成-160 已关闭-170 已撤销-180-->
                      <div v-if="item.order.status == 50" class="shenhezhong">
                        审批中
                      </div>
                      <div v-if="item.order.status == 100" class="daifukuan">
                        <span>待付款</span>
                      </div>
                      <div v-if="item.order.status == 110" class="daifukuan">
                        <span>支付中</span>
                      </div>
                      <div v-if="item.order.status == 140" class="daishouhuo">
                        待收货
                      </div>
                      <div v-if="item.order.status == 120" class="daifahuo">
                        待发货
                      </div>
                      <div v-if="item.order.status == 160" class="daishouhuo">
                        已完成
                      </div>
                      <div v-if="item.order.status == 170" class="yiguanbi">
                        已关闭
                      </div>
                      <div v-if="item.order.status == 180" class="yiguanbi">
                        已撤销
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="cld">
                      客户：
                      <span>{{ item.order.customerName }}</span>
                    </div>
                    <div class="su"></div>
                    <div class="cld">
                      收货方式：
                      <span>
                        {{
                          item.order.receiveType == 1 ? "送货上门" : "到仓自提"
                        }}
                      </span>
                    </div>
                  </li>
                  <li class="df flex-between">
                    <div class="cld">
                      <div>
                        {{
                          item.order.receiveType == 1 ? "收货人" : "提货人"
                        }}信息：
                      </div>

                      <span>
                        {{ item.order.takeName || item.order.receiveName }}
                        {{ item.order.takePhone || item.order.receivePhone }}
                        {{ item.order.receiveAddress }}
                      </span>
                    </div>
                    <div
                      v-if="item.endtime && item.order.status == 100"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                      "
                      class="fred tar"
                    >
                      <img
                        style="margin-right: 5px"
                        class="icon"
                        src="@/assets/images/person/warning.png"
                        alt
                      />
                      剩余付款时间： {{ item.endtime }}
                      <!-- 下错单了 -->
                    </div>
                  </li>
                </div>
              </div>
              <div>
                <div class="cardlist">
                  <div v-for="(it, idx) in item.orderItems" :key="idx">
                    <div v-if="idx < 2">
                      <div class="bigl">
                        <Product-Card :isMyorder="true" :rows="it">
                        </Product-Card>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.totalNum - 2 > 0" class="su"></div>
                  <div v-if="item.totalNum - 2 > 0" class="listNum">
                    <div class="num nwp">
                      剩余{{
                        item.totalNum - 2 > 0 ? item.totalNum - 2 : 0
                      }}类商品
                    </div>
                  </div>

                  <div v-if="item.totalNum - 2 > 0" class="su"></div>
                  <div class="listNum">
                    <div v-if="item.totalNum <= 2" style="margin-right:20px" class="su"></div>
                    <div class="caozuo">
                      <div v-if="item.order.status == 100 || item.order.status == 110" class="zhifu cup" @click="goPay(item)">
                        立即支付
                      </div>
                      <div @click="goDetail(item)" class="zhifu cup">
                        查看详情
                      </div>
                      <div @click="copyOrder(item)" class="zhifu cup">
                        再来一单
                      </div>
                      <div v-if="item.order.status == 100 || item.order.status == 110" @click="cancelPay(item)" class="xiangqing cup">
                        {{ item.order.status == 100 ? '取消订单' : '取消支付' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-if="total > 20" class="pagenation">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="20" :total="total"></el-pagination> -->
        <div>
          <el-pagination
            class="pagenation"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-sizes="[20, 30, 40, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div v-if="changeTab == 2 || changeTab == 4" class="daifukuanhd" :style="fixedComputed ? 'position: fixed' : ''">
      <div class="left">
        <el-checkbox @change="selectAll" v-model="payAll">全选</el-checkbox>
      </div>
      <div class="right">
        已选中订单
        <span style="color: #26c487">{{ checkPay.length }}</span
        >个
        <span
          v-if="changeTab == 2"
          @click="goPay('all')"
          class="op-cup"
          >去支付</span
        >
        <span
          v-if="changeTab == 4"
          @click="allConfirm"
          class="op-cup"
          >确认收货</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/custom-base/custom-product-card";

import {
  orderList,
  orderCount,
  groupProductReceived,
  orderCancel,
  orderCancelAPI,
  copyOrderAPI
} from "@/request/Orders/order";
import { findProductList } from "@/request/CartApi/cart";
export default {
  components: {
    ProductCard,
  },
  watch: {
    payAll: {
      handler(newVal, olVal) {
        if (!newVal) {
          this.checkPay = [];
        }
      },
      immediate: true,
    },
    $route: {
      immediate: true,
      handler(n, o) {
        if (n) {
          console.log(n)
          if (n.params.sn) {
            this.orderSn = n.params.sn
          }
          this.getList();
        }
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.timer = setInterval(() => {
      let _this = this;
      _this.daojishi();
    }, 1000);
  },
  activated() {
    this.getList();
  },
  data() {
    return {
      pageSize: 20,
      moreStatus: false,
      otherStatus: "更多状态",
      otherStatusName: [
        { name: "支付中", status: 110 },
        { name: "待发货", status: 120 },
        { name: "部分发货", status: 130 },
        { name: "已收货", status: 155 },
        { name: "已收货", status: 170 },
      ],
      //合并支付订单需要付多少钱
      payAmount: 0,
      loading: false,
      total: 1,
      page: 1,
      timer: null,
      productList: [],
      //全选待付款
      payAll: false,
      //单选选择待付款
      checkPay: [],
      //切换状态
      changeTab: 1,
      status: -1, //订单状态：-1全部 待付款-100 支付中-110 待发货-120 部分发货-130 待收货-140 部分收货-150 已收货-155 已完成-160 已关闭-170
      orderCount: {},
      contactsName: "",
      customerName: "",
      endDate: "",
      goodsName: "",
      orderSn: "",
      startDate: "",
    };
  },
  inject: ['fixed'],
  computed: {
    fixedComputed() {
      return this.fixed()
    }
  },
  methods: {
    goDetail(item) {
      this.changeTabFn(1);
      this.$router.push({
        name: "OrderDetail",
        params: { id: item.order.id },
      });
    },
    copyOrder(item) {
      this.$confirm('确认再来一单？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        copyOrderAPI(item.order.id).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.data)
            this.refreshCart();
          }
        });
      }).catch(() => {});
    },
    // 刷新购物车
    refreshCart() {
      const query = {customerId: this.$store.state.AddrId};
      findProductList(query).then((res) => {
        if (res.code === 200) {
          let productNum = res.data.productList.length;
          this.$store.commit("setProductNum", productNum);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    showtime(endtime) {
      var nowtime = new Date(), //获取当前时间
        endtime = new Date(endtime); //定义结束时间
      var lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
        leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
        lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
      if (leftd > -1 && lefth > -1) {
        if (leftd >= 1) {
          return leftd + "天" + lefth + "时" + leftm + "分" + lefts + "秒"; //返回倒计时的字符串
        } else if (leftd <= 0 && lefth >= 1) {
          return lefth + "时" + leftm + "分" + lefts + "秒"; //返回倒计时的字符串
        } else {
          return leftm + "分" + lefts + "秒"; //返回倒计时的字符串
        }
      }
    },
    serchList() {
      this.page = 1;
      this.getList();
    },
    daojishi() {
      this.productList.map((item) => {
        let endtime = "";
        endtime = this.showtime(item.order.payEndTime);
        item.endtime = endtime;
      });
      this.$forceUpdate();
    },
    //去支付
    goPay(item) {
      if (item === "all" && this.checkPay.length == 0) {
        this.$message.warning("请选择要结算的订单");
        return;
      }
      // if (item === "all" && this.checkPay.length >20) {
      //   this.$message.warning("单次最多支付20条订单");
      //   return;
      // }
      this.changeTabFn(1);
      if (item === "all" && this.checkPay.length > 0) {
        this.$router.push({ path: "/newPay", query: { checkPay: this.checkPay } });
      } else {
        this.$router.push({
          path: "/newPay",
          query: { checkPay: [item.order.id] },
        });
      }
    },
    changeTabFn(index, item) {
      switch (index) {
        case 1:
          //全部
          this.changeTab = index;
          this.status = -1;
          // this.otherStatus = "更多状态";
          break;
        case 2:
          // this.otherStatus = "更多状态";
          //待付款
          this.changeTab = index;
          this.status = 100;
          break;

        case 3:
          // this.otherStatus = "更多状态";
          //待收货
          this.changeTab = index;
          this.status = 110;
          break;
        case 4:
          // this.otherStatus = "更多状态";
          //已收货
          this.changeTab = index;
          this.status = 140;
          break;
        case 5:
          // this.otherStatus = "更多状态";
          //已完成
          this.changeTab = index;
          this.status = 160;
          break;
        case 6:
          // 待核价
          this.changeTab = index;
          this.status = 50;
          break;

        default:
          break;
      }
      this.getList();
    },
    //重置按钮
    resetFn() {
      this.contactsName = ""; //联系人名称
      this.customerName = ""; //客户名称
      this.endDate = "";
      this.goodsName = "";
      this.orderSn = "";
      this.startDate = "";
      this.getList(); 
    },

    //获取订单列表
    getList() {
      this.loading = true;
      let Postdata = {
        isCorporate: localStorage.getItem("isloan") ? true : false, //是否是法人
        contactsName: this.contactsName, //联系人名称
        customerAddrId: this.$store.state.AddrId, //客户地址id
        customerName: this.customerName, //客户名称
        endDate: null, //结束时间
        goodsName: this.goodsName, //商品名称
        isAsc: "asc", //排序
        orderSn: this.orderSn, //订单编号
        pageNum: this.page, //当前页
        pageSize: this.pageSize, //尺寸
        startDate: this.startDate, //开始日期
        status: this.status, //订单状态：-1全部 待付款-100 支付中-110 待发货-120 部分发货-130 待收货-140 部分收货-150 已收货-155 已完成-160 已关闭-170
      };
      if (!this.$store.state.AddrId) {
        this.productList = [];
        this.loading = false;
        return;
      }
      orderCount(this.$store.state.AddrId).then((res) => {
        if (res.code == 200) {
          this.orderCount = res.data;
        }
      });

      orderList(Postdata).then((res) => {
        if (res.code === 200) {
          this.productList = res.data.rows;
          this.total = res.data.total;
          //  this.productList.orderItems.totalNum =this.productList.orderItems.length
          this.productList.map((item) => {
            if (item.orderItems) {
              item.totalNum = item.order.productCategoryQuantity;
            }
            item.orderItems.map((it) => {
              it.transImg = it.productPic ? it.productPic.split(",") : "";
              if (it.transImg.length !== 0) {
                let urlImg = [];
                it.transImg.map((it) => {
                  if (it.lastIndexOf("show") !== -1) {
                    urlImg.push(it);
                  }
                });
                it.productPic =
                  urlImg.length > 0 ? urlImg[0] : it.productPic.split(",")?.[0];
              }
            });
          });

          this.daojishi();
        } else {
          this.productList = [];
        }
        this.loading = false;
        this.$forceUpdate();
      });
    },
    //单选
    choseinfo(item) {},
    //全选/反选
    selectAll() {
      if (this.payAll) {
        this.payAmount = 0;
        this.checkPay = [];
        this.productList.forEach((item) => {
          this.payAmount += item.order.payAmount;
          this.checkPay.push(item.order.id);
        });
      } else {
        this.payAmount = 0;
        this.checkPay = [];
      }
    },

    // 批量确认收货
    allConfirm(){
      if (this.checkPay.length == 0) {
        this.$message.warning("请选择要确认的订单");
        return;
      }
      this.$confirm('是否确认收货？确认收货后一天内可对该批订单进行售后处理', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        groupProductReceived(this.checkPay).then(res => {
          if(res.code === 200) {
            this.$message.success("确认收货成功！");
            this.getList();
            this.checkPay = [];
          }
        })
      }).catch(() => {});
    },

    // 取消支付
    cancelPay(item) {
      let data = { orderId: item.order.id };
      this.$confirm("是否取消支付该笔订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if(item.order.status == 100){
          orderCancel(data).then((res) => {
            if (res.code == 200) {
              this.$message.success("取消成功");
              this.getList();
            }
          });
        }
        if(item.order.status == 110){
          orderCancelAPI(data).then((res) => {
            if (res.code === 200) {
              this.$message.success("取消成功");
              this.getList();
            }
          });
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-loading-spinner i {
    color: #26c487;
  }
  .el-loading-spinner .el-loading-text {
    color: #26c487;
  }
  .el-checkbox__inner {
    border: 1px solid #26c487;
  }
  .el-tag {
    text-align: center;
    width: 50px;
    height: 24px;
    padding: 0;
    line-height: 24px;
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
  }
  .el-badge__content.is-fixed {
    position: absolute;
    top: 5px;
    right: 6px;
    transform: translateY(-50%) translateX(100%);
  }
  .el-tabs__item.is-active {
    color: @green;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 275px;
  }
  .el-tabs__active-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: @green;
    z-index: 1;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    list-style: none;
  }
}
.black {
  color: #333;
}
.realpay {
  font-size: 14px !important;
  font-family: DIN-Bold, DIN;
  font-weight: bold;
  color: #333 !important;
}
::v-deep {
  .paylog {
    .el-dialog__header {
      display: none;
    }
    .el-dialog {
      position: relative;
      margin: 0 auto 50px;
      background: none;
      border-radius: 2px;
      box-shadow: none;
      box-sizing: border-box;
    }
  }
  .el-tag {
    color: #fff;
    margin-left: 10px;
    background-color: #ff6700;
    border-color: #ff6700;
    width: 32px;
    height: 22px;
    padding: 0;
    line-height: 22px;
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
  }
}
.moreStatus {
  position: relative;
  .moreChild {
    font-size: 14px;
    white-space: nowrap;
    width: 100%;
    padding: 0 18px;
    left: 0;
    /* border-left: 1px solid #d7dae2;
        border-right: 1px solid #d7dae2;
        border-bottom: 1px solid #d7dae2; */
    text-align: center;
    background: #fff;
    position: absolute;
    top: 30px;
  }
}
.none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
}
.pagenation {
  padding-right: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
.shenhezhong {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: #E8F3FF;
  border-radius: 2px 2px 2px 2px;
  font-weight: 400;
  color: #165DFF;
}
.daifukuan {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: rgb(249, 235, 217);
  border-radius: 2px 2px 2px 2px;
  font-weight: 400;
  color: #faa743;
}
.daifukuanhd {
  // position: fixed;
  bottom: 0;
  z-index: 9;
  width: 1170px;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 47px;
  color: #000000;
  .op-cup {
    display: inline-block;
    width: 110px;
    height: 60px;
    background: #26C487;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    line-height: 60px;
    cursor: pointer;
  }
}
.daishouhuo {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: #d4efe7;
  border-radius: 2px 2px 2px 2px;
  color: #3ec993;
}
.daifahuo {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: #f8eadb;
  border-radius: 2px 2px 2px 2px;
  color: #f9a948;
}
.yiguanbi {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  width: 50px;
  height: 24px;
  background: #e9e9ed;
  border-radius: 2px 2px 2px 2px;
  color: #a2a3ae;
}
.serchtab {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .li {
    height: 36px;
    margin-bottom: 20px;
    line-height: 36px;
    width: 32%;
    display: flex;
    justify-content: space-between;
    .serchName {
      min-width: 60px;
      margin-right: 10px;
      font-size: 14px;
      text-align: right;
      color: #333333;
    }
    .ss {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .ipt {
      flex: 1;
      .el-input {
        width: 220px;
      }
    }
  }
}
.totalList {
  .hd {
    font-size: 16px;
    cursor: pointer;
    margin-right: 26px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-bottom: 4px solid transparent;
  }
  .active {
    color: @green;
    border-bottom: 4px solid @green;
  }
  .li {
    margin-bottom: 20px;
    width: 100%;
    background: #fff;
    border: 1px solid @hui;
    box-sizing: border-box;
    .head {
      width: 100%;
      /* height: 120px; */
      box-sizing: border-box;
      background: #f7f8fa;
      border-bottom: 1px solid @hui;
      padding: 18px 20px 11px 17px;
      .content {
        li {
          margin-bottom: 10px;
          line-height: 20px;
          font-size: 14px;
          display: flex;
          align-items: center;
          color: #595a6d;
          .cld {
            font-size: 12px;
            display: flex;
            color: #595a6d;
          }
          .su {
            margin: 0 14px;
            border: 1px solid #e7ebe7;
            display: inline-block;
            vertical-align: middle;
            border-left: 1px solid @hui;
            content: "";
            height: 12px;
            line-height: 12px;
          }
        }
      }
    }

    .cardlist {
      align-items: center;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 10px 22px;
      .su {
        margin-top: 2px;
        display: inline-block;
        vertical-align: middle;
        border-right: 1px solid @hui;
        content: "";
        height: 79px;
        padding-left: 10px;
        line-height: 79px;
      }
      .listNum {
        display: flex;
        align-items: center;
        padding: 0 6px;
        .num {
          font-size: 14px;
          color: #bbbccb;
        }
      }
      .bigl:nth-child(2n) {
        margin-left: 30px;
      }
      .caozuo {
        width: 80px;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .zhifu {
          font-size: 14px;
          color: #26c487;
          margin-bottom: 10px;
        }
        .xiangqing {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
